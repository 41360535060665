<template>
  <iframe :class="iframeClass" :src="url" />
</template>

<script setup>
import { onMounted } from 'vue'

defineProps({
  context: {
    type: Object,
    default: {},
  },
  url: {
    type: String,
    default: '',
  },
  iframeClass: {
    type: String,
    default: '',
  },
})

onMounted(() => {
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      //if esc key was not pressed in combination with ctrl or alt or shift
      const isNotCombinedKey = !(
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
      )
      if (isNotCombinedKey) {
        console.log('Escape key was pressed with out any group keys')
      }
    }
  })
})
</script>
