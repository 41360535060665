const cardStyle =
  'rounded-lg bg-white dark:bg-primary-950 border border-black-100 dark:border-brand-3/10 shadow dark:shadow-brand-1/10'
const cardAside = `${cardStyle} px-5 py-6`

const selectionHover =
  'transition-colors hover:text-primary hover:dark:text-white hover:bg-primary-50 hover:dark:bg-primary-900'
const proseContent =
  'prose dark:prose-invert my-6 md:my-14 max-w-full [&>_.container]:!px-0 [&>_.container_.row]:my-8 [&>_.container_iframe]:w-full [&>_.container_iframe]:md:h-[320px] [&>_.container_iframe]:h-[240px] [&>.container_.row_.video-wrapper_video]:w-full [&>_p:empty]:hidden'

const modalStyle =
  'p-6 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg dark:text-white'

export { cardAside, proseContent, selectionHover, cardStyle, modalStyle }
