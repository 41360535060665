import { useModal, useModalSlot } from 'vue-final-modal'

import ModalConfirm from '@/components/modals/ModalConfirm.vue'
import { fade, fadeScale } from '@/utils/transitions.js'

export default function useConfirm(attrs) {
  return new Promise((resolve, reject) => {
    const { open, close, destroy, options, patchOptions } = useModal({
      defaultModelValue: true,
      slots: {
        default: useModalSlot({
          component: ModalConfirm,
          attrs: {
            ...attrs,
            onConfirm: () => {
              resolve(true)
              close()
            },
            onClose: () => {
              close()
              resolve(false)
            },
          },
        }),
      },
      attrs: {
        class: 'flex justify-center items-center !z-[100000]',
        contentClass: '',
        overlayTransition: fade,
        contentTransition: fadeScale,
        // Bind props to the modal component (VueFinalModal in this case).
        clickToClose: true,
        escToClose: true,
      },
    })
  })
}
