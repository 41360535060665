<template>
  <div
    class="bg-white text-black dark:text-white dark:bg-primary-1000 min-h-svh"
  >
    <ModalsContainer />
    <RouterView />
  </div>
</template>

<script setup>
import { useCookies } from '@vueuse/integrations/useCookies'
import { inject } from 'vue'
import { ModalsContainer } from 'vue-final-modal'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'

const cookies = useCookies()

const route = useRoute()
const router = useRouter()
const auth = useAuthStore()
const theme = inject('theme')

theme.value = route.query.theme ?? 'light'

if (!cookies.get('nsmapp')) {
  router.push('/')
}

if (route.query.token) {
  auth.loginWithToken(route.query.token, false)
}
</script>
