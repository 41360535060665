<template>
  <div
    ref="wrapper"
    class="flex-col flex relative max-h-[67px]"
    :class="{
      'w-full fixed top-0 ': showMobileMenu,
      'overflow-y-scroll bg-white !max-h-none':
        showMobileMenu && showNavigation,
      ' overflow-visible': !showMobileMenu,
    }"
  >
    <div
      class="sticky z-[60] bg-white/80 text-black dark:bg-primary-1000/50 py-4 border-b border-white/50 dark:border-primary-950/50 dark:text-white"
      :class="{
        'backdrop-blur': !showNavigation,
        'border-gray-100 shadow':
          !searching && (!showNavigation || !showMobileMenu),
        '!bg-white dark:!bg-primary-1000/50': searching,
        'dark:!bg-primary-1000': showMobileMenu && showNavigation,
      }"
    >
      <div
        class="container relative mx-auto flex items-center flex-col lg:flex-row"
      >
        <div
          class="flex w-full lg:w-auto justify-between items-center"
          :class="{
            'sticky top-0 h-[73px] -m-4 z-[70]': showMobileMenu,
          }"
        >
          <a href="/" class="flex items-center">
            <UILogo class="w-[120px] lg:w-[164px] lg:mr-4" />
          </a>
          <div
            class="flex items-center flex-1 justify-end text-black-600 gap-1 md:gap-3 lg:hidden lg:ml-10"
          >
            <UIToggleTheme
              v-if="!themeExeption.includes($route.name)"
              class="lg:hidden"
            />
            <div v-if="auth.user" class="relative">
              <IconBell
                @click="$router.push('/profile/messages')"
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
              />
              <span
                v-if="auth.user.notifications_count"
                class="absolute -top-1.5 -right-1.5 flex h-3 w-3"
              >
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
                />
                <span
                  class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"
                />
              </span>
            </div>
            <div>
              <IconSearch
                v-if="!searching"
                @click="
                  () => {
                    searchValue = ''
                    toggleMobileMenu(false)
                    setView(true)
                    blockScroll()
                    focusOnInput()
                  }
                "
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
              />
              <IconX
                v-else
                @click="
                  () => {
                    setView(false)
                    allowScroll()
                  }
                "
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
              />
            </div>
            <IconUser
              @click="
                () => {
                  toggleMobileMenu(false)
                  if (!auth.user) callAuthModal()
                  if (auth.user) $router.push('/profile/main')
                }
              "
              class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
            />
            <div>
              <IconMenu2
                v-if="!showNavigation"
                @click="toggleMobileMenu"
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
              />
              <IconX
                @click="toggleMobileMenu"
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
                v-else
              />
            </div>
          </div>
        </div>

        <div
          class="flex-1 flex w-full lg:justify-center lg:mb-0 lg:w-auto lg:items-center"
          :class="{
            'min-h-[calc(100vh_-_72px)] max-h-[calc(100vh_-_72px)]':
              showMobileMenu,
          }"
          v-if="(showMobileMenu && showNavigation) || !showMobileMenu"
        >
          <div
            class="fixed lg:relative inset-0 overflow-y-auto lg:overflow-y-visible p-4 lg:p-0 dark:bg-primary-1000 bg-white lg:!bg-transparent z-[70]"
            @mouseleave="activeMenuItem = null"
          >
            <div class="flex justify-end lg:hidden">
              <IconX
                @click="toggleMobileMenu"
                class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-md"
              />
            </div>
            <div class="flex justify-center flex-col lg:mt-0 lg:flex-row">
              <div
                v-for="(link, i) in navigation"
                :key="i"
                class="lg:px-2 xl:px-3 lg:py-0 py-2 flex items-center cursor-pointer"
                :ref="(el) => setItemRef(el, i)"
                @mouseover="() => (activeMenuItem = i)"
              >
                <div class="flex flex-col">
                  <div
                    v-if="link.children?.length > 0"
                    class="flex items-center"
                    @click="
                      () => {
                        if (activeMobileMenuItem !== i) {
                          activeMobileMenuItem = i
                        } else {
                          activeMobileMenuItem = null
                        }
                      }
                    "
                  >
                    <div
                      class="text-2xl lg:text-sm whitespace-nowrap"
                      v-text="link.title"
                    />
                    <IconChevronDown
                      :class="{
                        'rotate-180':
                          activeMobileMenuItem === i || activeMenuItem === i,
                      }"
                      class="ml-1 h-4 w-4 translate-y-px transition-transform text-black-400 dark:text-white"
                    />
                  </div>
                  <router-link
                    v-else
                    :to="link.link"
                    active-class="text-primary"
                    @click="toggleMobileMenu(false)"
                  >
                    <div class="text-2xl lg:text-sm" v-text="link.title" />
                  </router-link>
                  <!--show children links on mobile-->
                  <Collapse
                    :when="activeMobileMenuItem === i"
                    class="lg:hidden mt-4"
                  >
                    <ul>
                      <li v-for="(childLink, i) in link?.children" :key="i">
                        <router-link
                          v-if="!childLink.external"
                          :to="childLink.link"
                          class="block cursor-pointer px-4 py-3"
                          @click="toggleMobileMenu(false)"
                        >
                          <div v-text="childLink.title" />
                          <div
                            class="text-xs leading-5 tracking-tight text-black-600 dark:text-black-300"
                            v-text="childLink.caption"
                          />
                        </router-link>
                        <a
                          v-else
                          :href="childLink.link"
                          @click="toggleMobileMenu(false)"
                          class="block cursor-pointer px-4 py-3"
                        >
                          <div v-text="childLink.title" />
                          <div
                            class="text-xs leading-5 tracking-tight text-black-600 dark:text-black-300"
                            v-text="childLink.caption"
                          />
                        </a>
                      </li>
                    </ul>
                  </Collapse>
                </div>
              </div>
            </div>

            <Transition v-bind="fadeScale">
              <div
                v-if="activeMenuState"
                :style="`transform: translate(${activeMenuState.x}px, ${activeMenuState.y}px)`"
                class="absolute hidden lg:block left-0 top-0 z-[9999] rounded-lg bg-transparent pt-10 transition-all"
              >
                <div class="flex flex-col items-center bg-transparent">
                  <div
                    ref="container"
                    class="animating-height w-[240px] overflow-hidden"
                    :class="[
                      navigation[activeMenuItem]?.children
                        ? ''
                        : '!border-none',
                      cardStyle,
                    ]"
                  >
                    <div ref="containerHeight">
                      <div
                        v-for="(link, i) in navigation[activeMenuItem]
                          ?.children"
                        :key="i"
                      >
                        <router-link
                          v-if="!link.external"
                          :to="link.link"
                          class="block cursor-pointer px-4 py-3"
                          :class="[
                            selectionHover,
                            {
                              '!bg-primary-100/80 dark:!bg-primary-800/30 hover:dark:!bg-primary-800/30':
                                route.fullPath.includes(link.link),
                            },
                          ]"
                        >
                          <div v-text="link.title" />
                          <div
                            class="max-w-[90%] text-xs leading-5 tracking-tight text-black-600 dark:text-black-300"
                            v-text="link.caption"
                          />
                        </router-link>
                        <a
                          v-else
                          :href="link.link"
                          class="block cursor-pointer px-4 py-3 transition-colors hover:text-primary hover:dark:text-white hover:bg-primary-50 hover:dark:bg-primary-900"
                        >
                          <div v-text="link.title" />
                          <div
                            class="max-w-[90%] text-xs leading-5 tracking-tight text-black-400"
                            v-text="link.caption"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
          <button
            class="p-1"
            @click="
              () => {
                setView(!searching)
                focusOnInput()
              }
            "
          >
            <IconSearch
              class="size-4 lg:block hidden cursor-pointer text-black-400 translate-y-px"
            />
          </button>
        </div>

        <div class="flex items-center gap-3">
          <UIToggleTheme
            v-if="!themeExeption.includes($route.name)"
            class="hidden lg:block"
          />

          <div v-if="auth.user" class="hidden lg:block relative">
            <IconBell
              @click="$router.push('/profile/messages')"
              class="cursor-pointer dark:bg-white/20 dark:text-white bg-black/5 p-1.5 w-8 h-8 rounded-lg"
            />
            <span
              v-if="auth.user.notifications_count"
              class="absolute -top-1.5 -right-1.5 flex h-3 w-3"
            >
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
              />
              <span
                class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"
              />
            </span>
          </div>

          <UIButton
            v-if="!auth.user"
            :class="{ 'hidden lg:block': !showNavigation || showMobileMenu }"
            @click="
              () => {
                toggleMobileMenu(false)
                callAuthModal()
              }
            "
            title="Войти"
            type="gray"
            :icon="IconUser"
            class="lg:w-min w-full border-none"
          />
          <router-link
            class="lg:w-min w-full"
            :class="{ 'hidden lg:block': !showNavigation || showMobileMenu }"
            v-else
            to="/profile/main"
          >
            <UIButton
              class="w-full whitespace-nowrap"
              :title="auth.user.title"
              type="gray"
              :icon="
                auth.hasEsia
                  ? auth.user.logged_in_by_esia
                    ? iconEsia
                    : IconStar
                  : IconUser
              "
            />
          </router-link>
        </div>
      </div>
    </div>

    <transition v-bind="searchTransition">
      <div v-if="searching" class="left-0 right-0 z-50 shadow">
        <div
          class="pt-2 lg:pt-10 pb-12 px-8 lg:px-0 bg-white dark:bg-primary-1000/50 backdrop-blur rounded-b-md overflow-hidden"
        >
          <div class="flex items-top justify-center gap-2">
            <div class="w-full lg:w-min">
              <FormKit
                ref="inputRef"
                v-model="searchValue"
                @input="clearResults"
                :classes="{
                  outer: '!mb-0',
                  inner: '!mb-0 lg:min-w-[660px]',
                }"
                class=""
                type="text"
                :placeholder="placeholder"
              >
                <template #suffix="context">
                  <div
                    v-if="searchValue"
                    class="flex items-center justify-center"
                  >
                    <button
                      v-if="!searchLoading"
                      tabindex="-1"
                      class="px-3 text-black-400 dark:text-white transition hover:text-black-900"
                      @click.stop.prevent="
                        () => {
                          searchValue = ''
                        }
                      "
                    >
                      <IconX v-if="context.value" class="h-5 w-5" />
                    </button>
                    <LoadingIcon v-else class="mr-3 text-primary w-5 h-5" />
                  </div>
                </template>
              </FormKit>
              <div v-if="searchValue.length" class="mt-5 lg:max-w-[660px]">
                <p
                  class="text-xs ml-4 mb-2.5 text-black-600 text-black-600 dark:text-black-300 uppercase leading-[24px] tracking-[1.2px]"
                  v-text="`Результаты поиска (${searchResult?.length})`"
                />
                <div v-if="searchResult.length">
                  <div
                    v-for="(item, index) in searchResult"
                    :key="item.id"
                    class="px-4 rounded-lg transition cursor-pointer hover:bg-mine-shaft-50 hover:dark:bg-primary-950 py-2.5"
                    @click="open(item)"
                  >
                    <p class="text-base font-bold" v-text="item.title" />
                    <p
                      class="text-base mt-1 leading-[130%] text-black-600 text-black-600 dark:text-black-300"
                    >
                      <span v-text="item.type?.label" />
                      <span v-if="item.type?.label"> · </span>
                      <span
                        class="text-base mt-1 leading-[130%]"
                        v-text="getDateFormat(item.created_at)"
                      />
                    </p>
                  </div>
                </div>
                <div
                  v-else
                  class="px-4 text-black-400"
                  v-text="
                    searchLoading ? 'Производим поиск...' : 'Ничего не найдено'
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition v-bind="fadeScrim">
      <div
        v-if="searching"
        @click="
          () => {
            setView(false)
            searchValue = ''
            allowScroll()
          }
        "
        class="fixed opacity-80 z-40 inset-0 h-screen bg-slate-950"
      />
    </transition>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { watchDebounced } from '@vueuse/shared'
import { computed, h, inject, onMounted, onUnmounted, ref, watch } from 'vue'
import { Collapse } from 'vue-collapsed'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from '@/store/auth.js'
import { useGlobalStore } from '@/store/global.js'

import {
  IconBell,
  IconChevronDown,
  IconMenu2,
  IconSearch,
  IconStar,
  IconUser,
  IconX,
} from '@tabler/icons-vue'

import LoadingIcon from '@/components/loading/LoadingIcon.vue'
import UIButton from '@/components/ui/UIButton.vue'
import UILogo from '@/components/ui/UILogo.vue'
import UIToggleTheme from '@/components/ui/UIToggleTheme.vue'
import { callAuthModal, getDateFormat } from '@/utils/global.js'
import { fadeScale, fadeScrim, searchTransition } from '@/utils/transitions.js'
import { cardStyle, selectionHover } from '@/utils/ui.js'

const iconEsia = () =>
  h(
    'div',
    {
      class: '-ml-1',
    },
    h('img', {
      src: '/images/socials/gosuslugi.svg',
      class: 'brightness-200 contrast-200 opacity-70 h-4 w-4 mr-2',
    }),
  )

const router = useRouter()

const auth = useAuthStore()

const wrapper = ref(null)

onClickOutside(wrapper, (event) => {
  setView(false)
  searchValue.value = ''
  allowScroll()
})

const setItemRef = (el, i) => {
  itemRefs.value[i] = el
}

const activeMenuState = computed(() => {
  if (activeMenuItem.value) {
    const el = itemRefs.value[activeMenuItem.value]
    const rect = el.getBoundingClientRect()
    const parent = el.parentElement.getBoundingClientRect()
    return {
      x: rect.x - parent.x - (240 - rect.width) / 2,
      y: parent.height,
    }
  }

  return null
})

const activeMenuItem = ref(null)
const activeMobileMenuItem = ref(null)
const itemRefs = ref({})

const global = useGlobalStore()

const searching = ref(false)
const searchLoading = ref(false)
const inputRef = ref(null)

const showNavigation = ref(false)
const mediaQuery = window.matchMedia('(max-width:1024px)')
const showMobileMenu = ref(mediaQuery.matches)
const listener = (e) => (showMobileMenu.value = e.matches)

function toggleMobileMenu(newState = null) {
  if (newState === true || newState === false) showNavigation.value = !newState
  activeMobileMenuItem.value = null
  searching.value = false

  if (showNavigation.value) {
    allowScroll()
  } else {
    blockScroll()
  }

  showNavigation.value = !showNavigation.value
}

function blockScroll() {
  document.body.style.overflow = 'hidden'
}

function allowScroll() {
  document.body.style.overflow = 'auto'
}

onMounted(() => {
  mediaQuery.addEventListener('change', listener)
})

onUnmounted(() => {
  mediaQuery.removeEventListener('change', listener)
})

const navigation = ref([
  {
    title: 'Блог',
    link: '/blog',
  },
  {
    title: 'Афиша',
    link: '/afisha',
  },
  {
    ...global.resources?.menu?.activities,
  },
  {
    ...global.resources?.menu?.projects,
  },
  {
    title: 'Сервисы',
    link: '/',
    children: global.resources.services.map((service) => ({
      title: service.title,
      link: service.link,
      external: true,
    })),
  },
  {
    ...global.resources?.menu?.contests,
  },
])

const route = useRoute()

// Hide menu if route change
watch(
  () => route.path,
  () => {
    activeMenuItem.value = null
  },
)

const searchResult = ref(null)
const searchValue = ref('')

const routingMap = ref({
  events: 'afisha',
  posts: 'blog',
})

const placeholder = ref('')
createRandomPlaceholder()

const http = inject('http')
let abortController = null

watchDebounced(
  searchValue,
  () => {
    handleInput(searchValue.value)
  },
  { debounce: 100, maxWait: 500 },
)

function handleInput(value) {
  if (abortController) {
    abortController.abort()
  }

  abortController = new AbortController()

  searchLoading.value = true
  http('search', {
    method: 'GET',
    signal: abortController.signal,
    params: {
      query: value,
    },
  })
    .then(({ resources }) => {
      searchResult.value = resources
    })
    .finally(() =>
      setTimeout(() => {
        searchLoading.value = false
      }, 300),
    )
}

function open(item) {
  searching.value = false
  searchResult.value = []
  searchValue.value = ''

  router.push(`/${routingMap.value[item.type.value]}/${item.id}`)
}

function clearResults() {
  searchResult.value = []
  createRandomPlaceholder()
}

function createRandomPlaceholder() {
  const joke = Math.random() < 0.02
  placeholder.value = joke ? 'Тебе никто не поверит...' : 'Введите запрос...'

  if (joke) {
    setTimeout(() => {
      placeholder.value = 'Введите запрос...'
    }, 5000)
  }
}

function focusOnInput() {
  setTimeout(() => {
    if (searching.value) {
      inputRef.value?.$el?.querySelector('input').focus()
    }
  }, 400)
}

function setView(val) {
  searching.value = val
  if (val) {
    createRandomPlaceholder()
  }
}

watch(route, () => {
  searching.value = false
})

const themeExeption = []
</script>
