<template>
  <div class="text-black flex flex-col gap-2 p-2" @click.prevent>
    <UIButton
      title="Подключить ЕСИА"
      size="xs"
      @click.prevent.stop="handleClick"
    />
    <span
      class="text-sm text-black dark:text-white"
      v-if="body"
      v-html="body"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'

import UIButton from '@/components/ui/UIButton.vue'
import { callAuthModal } from '@/utils/global.js'

const props = defineProps({
  hide: {
    type: Function,
    default: () => {},
  },
  body: {
    type: String,
    default: '',
  },
})

const router = useRouter()

function handleClick() {
  props.hide()
  router.push('/profile/auth')
}
</script>
