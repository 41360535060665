<template>
  <!--scrim-->
  <transition v-bind="fadeScrim">
    <div
      v-if="showPrompt && instructions !== 'none'"
      class="bg-black-950/70 fixed -inset-20 z-[99998]"
      @click="
        () => {
          showPrompt = false
          router.replace({ query: { showPwa: 'shown' } })
        }
      "
    />
  </transition>
  <!--bottom sheet-->
  <transition v-bind="bottomSheetSlide">
    <div
      v-if="showPrompt && instructions !== 'none'"
      class="bg-white pointer-events-none dark:bg-black-950 user-select-none transition-all fixed bottom-0 left-0 right-0 h-[80vh] z-[99999] rounded-t-xl p-8 pt-0"
    >
      <div
        class="mt-2 mb-4 w-12 h-1.5 absolute left-1/2 -translate-x-1/2 rounded-full bg-black-300 dark:bg-black-800"
      />
      <div
        v-if="instructions === 'android_manual'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="flex-1 self-stretch bg-no-repeat mt-8 relative bg-contain bg-top"
          style="background-image: url(/images/pwa/mobile_install_1.png)"
        />
        <p class="mt-6 text-center">
          Для этого откройте меню браузера и нажмите
          <b>«Установить&NonBreakingSpace;приложение»</b>
        </p>
        <p class="text-sm mt-2 opacity-60 mb-16 text-center">
          В некоторых браузерах это может называться по-другому:
          <b
            >«Добавить&NonBreakingSpace;на&NonBreakingSpace;главный&NonBreakingSpace;экран»</b
          >
        </p>
      </div>

      <div
        v-else-if="instructions === 'android'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="flex-1 self-stretch bg-no-repeat mt-8 relative bg-contain bg-top"
          style="background-image: url(/images/pwa/mobile_install_2.png)"
        />

        <UIButton
          class="mt-8 mb-12 pointer-events-auto absolute left-1/2 -translate-x-1/2 w-full"
          title="Добавить"
          size="xl"
          @click="deferredPrompt.prompt()"
        />
      </div>

      <div
        v-else-if="instructions === 'ios'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="mx-16 h-1/2 self-stretch bg-no-repeat mt-8 relative bg-contain bg-center"
          style="background-image: url(/images/pwa/pwa_logo.png)"
        />
        <div class="mb-18">
          <p>
            <span class="float-left">Нажмите</span>
            <img
              class="w-5 h-5 float-left mx-1"
              src="/images/pwa/ios_share_icon.png"
              alt="icon share"
            />
            <b>Поделиться</b>
            в нижнем меню браузера и выберите
            <b>На экран &laquo;домой&raquo;</b>, затем &ndash;
            <b>Добавить</b>
          </p>
        </div>
      </div>

      <div
        v-else-if="instructions === 'open_safari'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="mx-16 h-full self-stretch bg-no-repeat mt-8 relative bg-contain bg-center"
          style="background-image: url(/images/pwa/pwa_logo.png)"
        />
        <div class="mb-20">
          <p
            class="mt-3 text-lg text-center"
            v-text="
              'Для этого откройте эту страницу в Safari. Там расскажем, что делать дальше'
            "
          />
          <span
            class="h-6 bg-center bg-contain bg-no-repeat"
            style="background-image: url(/images/pwa/ios_share_icon.png)"
          />
          <UIButton
            v-if="isSupported"
            class="mt-6 pointer-events-auto absolute left-1/2 -translate-x-1/2 w-full"
            :title="!copied ? 'Скопировать ссылку' : 'Скопировано'"
            size="xl"
            @click="copyLink"
          />
        </div>
      </div>
      <div
        v-else-if="instructions === 'open_chrome'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="mx-16 h-full self-stretch bg-no-repeat mt-8 relative bg-contain bg-center"
          style="background-image: url(/images/pwa/pwa_logo.png)"
        />
        <div class="mb-20">
          <p
            class="mt-3 text-lg text-center"
            v-text="
              'Для этого откройте эту страницу в Chrome. Там расскажем, что делать дальше'
            "
          />
          <UIButton
            v-if="isSupported"
            class="mt-6 pointer-events-auto absolute left-1/2 -translate-x-1/2 w-full"
            :title="!copied ? 'Скопировать ссылку' : 'Скопировано'"
            size="xl"
            @click="copyLink"
          />
        </div>
      </div>
      <div
        v-else-if="instructions === 'open_not_private'"
        class="mt-12 flex flex-col h-full"
      >
        <UIHeading
          class="text-center"
          title="Вы можете добавить наш сайт на рабочий стол"
          tag="3"
        />
        <div
          class="mx-16 h-full self-stretch bg-no-repeat mt-8 relative bg-contain bg-center"
          style="background-image: url(/images/pwa/pwa_logo.png)"
        />
        <div class="mb-20">
          <p
            class="mt-3 text-lg text-center"
            v-text="
              'Для этого откройте эту страницу без режима инкогнито. Там расскажем, что делать дальше'
            "
          />
          <UIButton
            v-if="isSupported"
            class="mt-6 pointer-events-auto absolute left-1/2 -translate-x-1/2 w-full"
            :title="!copied ? 'Скопировать ссылку' : 'Скопировано'"
            size="xl"
            @click="copyLink"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { useClipboard } from '@vueuse/core'
import { detectIncognito } from 'detectincognitojs'
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import UIButton from '@/components/ui/UIButton.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import { bottomSheetSlide, fadeScrim } from '@/utils/transitions.js'

const route = useRoute()
const router = useRouter()

const showPrompt = ref(false)

// PWA installation state:
// { supported: boolean | undefined, installed: boolean, lastShow: time ms | undefined }
let state = localStorage.getItem('pwa-state')

if (!state) {
  state = {
    installed: false,
  }
} else state = JSON.parse(state)

function saveState() {
  localStorage.setItem('pwa-state', JSON.stringify(state))
}

// scenario:
// IOS + safari             | ios
// IOS + not safari         | open_safari
// android + not chrome     | open_chrome
// incognito, chrome        | open_not_private
// android + supported      | android
// not supported (or force) | android_manual
// else                     | none

const instructions = ref('none')

const normalizedUserAgent = navigator.userAgent.toLowerCase()
const isIos =
  /ip(ad|hone|od)/.test(normalizedUserAgent) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
const isSafari =
  /Safari/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent)
const standalone = navigator.standalone

// no need to show prompt
if (standalone) state.installed = true

const deferredPrompt = ref(null)

onMounted(() => {
  state.supported = 'BeforeInstallPromptEvent' in window

  showDialog(route.query.showPwa === 'true')
})

function showDialog(force = false) {
  instructions.value = 'none'

  if (!force && state.installed !== true && state.lastShow === undefined)
    if (state.supported) {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        deferredPrompt.value = e

        tryShowPrompt()
      })
      // if (force) tryShowPrompt() // we cant wait for event in force open, even if it is supported by the browser :((((
    } else {
      tryShowPrompt()
    }
  if (force) tryShowPrompt()
}

const isChrome =
  !!window.chrome &&
  !window.opr &&
  window.navigator.vendor === 'Google Inc.' &&
  window.navigator.userAgent.indexOf('Edg') === -1 &&
  window.navigator.userAgent.indexOf('Bra') === -1 &&
  window.navigator.userAgent.indexOf('Ya') === -1 &&
  window.navigator.userAgent.indexOf('Fire') === -1
async function tryShowPrompt() {
  if (!isMobile()) return
  const isPrivate = (await detectIncognito()).isPrivate

  if (state.supported && deferredPrompt.value && deferredPrompt.value.prompt) {
    if (isIos) instructions.value = 'ios'
    else if (isChrome) {
      instructions.value = isPrivate ? 'open_not_private' : 'android'
    } else instructions.value = 'open_chrome'
    showPrompt.value = true
  } else {
    if (isIos) {
      if (isSafari) instructions.value = isPrivate ? 'open_not_private' : 'ios'
      else {
        instructions.value = 'open_safari'
        // router.push({ path: route.fullPath, query: { showPwa: 'true' } })
      }
    } else {
      if (isChrome) {
        instructions.value = isPrivate ? 'open_not_private' : 'android_manual'
      } else instructions.value = 'open_chrome'
    }
    showPrompt.value = true
  }
  state.lastShow = Date.now()
  saveState()
}

watch(
  () => route.query,
  () => {
    if (route.query.showPwa === 'true') {
      showDialog(true)
    }
  },
)

function isMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substring(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

const sourceLink = ref('')
const { copy, isSupported, copied } = useClipboard({ legacy: true })

function copyLink() {
  sourceLink.value = window.location.href + '?showPwa=true'
  copy(sourceLink.value)
}
</script>
