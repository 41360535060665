const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio') &&
  node.props.options

export function asteriskPlugin(node) {
  node.on('created', () => {
    const isRequired = node.context.attrs.required
    if (!isRequired) return

    const isMultiOption = isCheckboxAndRadioMultiple(node)

    // if we're going to modify the schema then we need
    // to update the schemaMemoKey so we don't get an
    // invalid cached schema.
    node.props.definition.schemaMemoKey = `required_${
      isMultiOption ? 'multi_' : ''
    }${node.props.definition.schemaMemoKey}`

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      if (isRequired) {
        if (isMultiOption) {
          sectionsSchema.legend = {
            children: ['$label'],
          }
        } else {
          sectionsSchema.label = {
            children: [
              '$label',
              {
                $el: 'span', // try an h1!
                attrs: {
                  style: { color: 'red' },
                  class: 'ml-1 text-2xl',
                },
                children: '*',
              },
            ],
          }
        }
      }
      return schemaFn(sectionsSchema)
    }
  })
}
