<template>
  <button
    class="z-10 flex origin-center cursor-pointer focus-visible:ring-2 ring-primary outline-none items-center rounded-lg bg-black/5 p-1.5 size-8 dark:bg-white/20 overflow-hidden transition-transform"
    @click="toggleTheme"
  >
    <Transition v-bind="contentTransition" mode="out-in">
      <component
        class="size-5"
        :class="[
          { 'p-0.5': theme === 'light' },
          theme === 'light' ? moonColor : sunColor,
        ]"
        :is="theme === 'dark' ? IconSun : IconMoon"
      />
    </Transition>
  </button>
</template>

<script setup>
import { inject, ref } from 'vue'

import { IconMoon, IconSun } from '@tabler/icons-vue'

const theme = inject('theme')

function toggleTheme() {
  theme.value = theme.value === 'dark' ? 'light' : 'dark'
}

defineProps({
  moonColor: {
    type: String,
    default: 'text-primary',
  },
  sunColor: {
    type: String,
    default: 'text-yellow-500',
  },
})

const contentTransition = ref({
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-full',
  enterToClass: 'opacity-100 translate-y-0',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0',
  leaveToClass: 'opacity-0 -translate-y-full',
})
</script>
