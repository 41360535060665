import { h, reactive, ref } from 'vue'
import { useModal, useModalSlot } from 'vue-final-modal'

import { IconX } from '@tabler/icons-vue'

import { modalStyle } from '@/utils/ui.js'

const overlayTransition = ref({
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0',
  enterToClass: 'opacity-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100',
  leaveToClass: 'opacity-0',
})

const contentTransition = ref({
  enterActiveClass: 'ease-out duration-300',
  enterFromClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
  enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveActiveClass: 'ease-in duration-200',
  leaveFromClass: 'opacity-100 translate-y-0 sm:scale-100',
  leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
})

export default function useModalDynamic({
  component,
  compAttrs,
  modalAttrs,
  opened,
  containerClass,
  closeButton,
  fullScreen,
  clearDataAfterClose,
}) {
  const isLoading = ref(false)
  const isError = ref(false)
  const data = ref({})
  const content = containerClass || fullScreen || 'py-14'
  const context = reactive({
    isLoading: isLoading,
    isError: isError,
    data: data,
    wrapperClass: modalStyle,
    close: () => close(),
  })

  const { open, close, destroy, options, patchOptions } = useModal({
    defaultModelValue: opened || false,
    slots: {
      default: useModalSlot({
        component: () =>
          h(
            'div',
            {
              class: `absolute flex inset-0 h-full overflow-auto ${content}`,
              onMousedown: (event) => {
                if (event.target !== event.currentTarget) return
                close()
              },
            },
            [
              h(component, { context, ...compAttrs }),
              closeButton
                ? h(
                    'button',
                    {
                      class:
                        'absolute top-2 right-1 md:right-6  md:top-6 dark:text-white text-primary',
                      onClick: () => close(),
                    },
                    h(IconX, { class: 'w-10 h-10' }),
                  )
                : null,
            ],
          ),
      }),
    },
    attrs: {
      class: 'flex overflow-y-auto',
      overlayClass: 'fixed',
      contentClass: 'absolute inset-0',
      overlayTransition: overlayTransition,
      contentTransition: contentTransition,
      // Bind props to the modal component (VueFinalModal in this case).
      clickToClose: true,
      escToClose: true,
      ...modalAttrs,
      onClosed: () => {
        if (clearDataAfterClose) {
          data.value = {}
        }
      },
    },
  })

  return {
    open,
    close,
    destroy,
    options,
    patchOptions,
    isLoading,
    data,
  }
}
