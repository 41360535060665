<template>
  <div class="flex flex-col gap-2 p-2" @click.prevent>
    <UIButton
      title="Авторизуйтесь"
      size="xs"
      @click.prevent.stop="handleClick"
    />
    <span
      class="text-sm text-black dark:text-white"
      v-if="body"
      v-html="body"
    />
  </div>
</template>

<script setup>
import UIButton from '@/components/ui/UIButton.vue'
import { callAuthModal } from '@/utils/global.js'

const props = defineProps({
  hide: {
    type: Function,
    default: () => {},
  },
  body: {
    type: String,
    default: '',
  },
})

function handleClick() {
  props.hide()
  callAuthModal()
}
</script>
