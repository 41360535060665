<template>
  <div
    class="flex relative flex-col m-auto max-w-[620px] pb-12 w-full"
    :class="context.wrapperClass"
  >
    <button class="max-md:hidden" @click="context.close">
      <IconX
        class="absolute size-4 right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full"
      />
    </button>
    <div class="flex flex-col h-full">
      <div class="space-y-4">
        <UIHeading
          title="Инструкции и документы"
          tag="2"
          styles="2"
          class="mb-6"
        />
        <div>
          <span
            v-text="'Полезные материалы'"
            class="block mb-3 dark:text-white/40"
          />

          <ul v-if="options.links.length" class="space-y-3">
            <li v-for="(link, i) in options.links" :key="i">
              <a
                :href="`https://${link.link}`"
                target="_blank"
                class="bg-primary-50 dark:bg-white/10 rounded flex justify-between items-center py-4 px-5"
              >
                <span v-text="link.title" />
                <IconChevronRight class="w-5 h-5" />
              </a>
            </li>
          </ul>
          <span v-else>—</span>
        </div>
        <div>
          <span
            v-text="'Документы доступные для загрузки'"
            class="block mb-3 dark:text-white/40"
          />
          <ul v-if="options.files.length" class="space-y-3">
            <li v-for="file in options.files" :key="file.id">
              <UIFileTile
                :id="file.id"
                :name="file.name"
                :extension="file.extension"
                :human_size="formatBytes(file.size)"
                :path="file.path"
              />
            </li>
          </ul>
          <span v-else>—</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/store/global.js'

import { IconChevronRight, IconX } from '@tabler/icons-vue'

import UIFileTile from '@/components/ui/UIFileTile.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import { formatBytes } from '@/utils/global.js'

const emits = defineEmits(['close', 'force-close'])
const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  options: {
    type: Object,
    default: {},
  },
})

const global = useGlobalStore()
</script>
