<template>
  <div class="m-auto md:max-w-[60%] relative w-full p-8" :class="modalStyle">
    <button
      class="absolute max-md:hidden right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full"
      @click="$emit('close')"
    >
      <IconX class="size-4" />
    </button>
    <div>
      <UIHeading :title="title" tag="2" class="mb-8" />

      <YandexMap
        :settings="{
          location: addresses[0]
            ? {
                center: [
                  addresses[0].latitude,
                  addresses[0].longitude,
                ].reverse(),
                zoom: 15,
              }
            : {
                center: center,
                zoom: zoom,
              },
          theme,
        }"
        real-settings-location
        class="rounded border border-neutral-200 overflow-hidden mt-2"
        :height="'500px'"
      >
        <YandexMapFeature
          v-if="addressesFeatures.length"
          v-for="(item, i) in addressesFeatures"
          :settings="item"
        />
        <YandexMapMarker
          v-for="address in addresses"
          :key="address.fias_id"
          :settings="{
            coordinates: [address.latitude, address.longitude].reverse() || [],
          }"
        >
          <svg
            width="36"
            height="41"
            viewBox="0 0 36 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            :style="{
              minWidth: '50px',
              position: 'relative',
              boxSizing: 'border-box',
              transform: 'translate(-50%, calc(-50% - 24px))',
            }"
          >
            <path
              class="dark:fill-orange-400"
              d="M30.7281 5.27211C34.0328 8.57638 35.9228 13.0363 35.9986 17.7089C36.0743 22.3815 34.3299 26.9004 31.1341 30.3101L30.7281 30.7301L22.2421 39.2141C21.1654 40.2902 19.7199 40.9167 18.1984 40.9669C16.677 41.017 15.1934 40.487 14.0481 39.4841L13.7601 39.2141L5.27211 30.7281C1.89644 27.3524 0 22.774 0 18.0001C0 13.2262 1.89644 8.64779 5.27211 5.27211C8.64779 1.89644 13.2262 0 18.0001 0C22.774 0 27.3524 1.89644 30.7281 5.27211ZM18.0001 12.0001C17.2122 12.0001 16.432 12.1553 15.704 12.4568C14.9761 12.7584 14.3146 13.2003 13.7575 13.7575C13.2003 14.3146 12.7584 14.9761 12.4568 15.704C12.1553 16.432 12.0001 17.2122 12.0001 18.0001C12.0001 18.788 12.1553 19.5683 12.4568 20.2962C12.7584 21.0242 13.2003 21.6856 13.7575 22.2428C14.3146 22.7999 14.9761 23.2419 15.704 23.5434C16.432 23.8449 17.2122 24.0001 18.0001 24.0001C19.5914 24.0001 21.1175 23.368 22.2428 22.2428C23.368 21.1175 24.0001 19.5914 24.0001 18.0001C24.0001 16.4088 23.368 14.8827 22.2428 13.7575C21.1175 12.6323 19.5914 12.0001 18.0001 12.0001Z"
              fill="#3A48C6"
            />
          </svg>
        </YandexMapMarker>

        <YandexMapDefaultFeaturesLayer />
        <YandexMapDefaultSchemeLayer />
      </YandexMap>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'
import {
  YandexMap,
  YandexMapDefaultFeaturesLayer,
  YandexMapDefaultSchemeLayer,
  YandexMapFeature,
  YandexMapMarker,
} from 'vue-yandex-maps'

import { IconX } from '@tabler/icons-vue'

import UIHeading from '@/components/ui/UIHeading.vue'
import { modalStyle } from '@/utils/ui.js'

const theme = inject('theme')
const center = [33, 68.9545]
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  zoom: {
    type: Number,
    default: 8,
  },
  addresses: {
    type: Array,
    default: [],
  },
})

const addressesFeatures = computed(() => {
  return (
    props.addresses
      .filter((item) => item.meta && item.meta.features.length > 0)
      .flatMap((item) => item?.meta?.features)
      .filter((item) => item?.geometry?.type !== 'Point') || []
  )
})
</script>
