<template>
  <div
    class="flex relative flex-col m-auto max-w-[460px] w-full"
    :class="context.wrapperClass"
  >
    <button class="max-md:hidden" @click="context.close">
      <IconX
        class="absolute size-4 right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full"
      />
    </button>
    <LoadingView :loading="isLoading">
      <button
        v-if="resources.length > 1 && selectedResourceId && !id"
        @click="selectedResourceId = null"
        class="flex items-center gap-1"
      >
        <IconChevronLeft class="size-4" />
        Назад
      </button>
      <LoadingView
        v-if="selectedResourceId"
        class="my-6"
        :loading="isLoadingQr"
      >
        <div
          v-html="qr"
          class="w-full [&>svg]:p-0 [&>svg]:!w-full [&>path]:!w-full text-primary-800 dark:[&_path]:fill-white"
        />
      </LoadingView>
      <div v-if="selectedResourceId">
        <UIHeading
          :title="`Заказ №${selectedResourceId}`"
          tag="4"
          class="mb-3"
        />
        <p
          v-text="caption"
          class="mb-8 text-sm text-black-700 dark:text-white"
        />

        <div v-if="selectedResource.shop" class="mb-6">
          <UIHeading :title="selectedResource.shop.name" tag="4" />
          <p
            v-if="selectedResource.shop.caption"
            class="prose text-black-900 dark:text-black-200 dark:prose-invert !mt-1 max-w-full [&>_.container]:!px-0"
            v-html="selectedResource.shop.caption"
          />
          <div class="mt-3 flex gap-2 items-center">
            <IconMapPin class="w-5 h-5 text-black-400 dark:text-black-500" />
            <p
              class="text-black-950 dark:text-black-50"
              v-text="
                selectedResource.shop.address_raw ??
                selectedResource.shop.address.title
              "
            />
          </div>
          <div class="mt-3 flex gap-2 items-center">
            <IconPhone class="w-5 h-5 text-black-400 dark:text-black-500" />
            <a
              :href="`tel:${selectedResource.shop.phone}`"
              class="text-black-950 dark:text-black-50"
              v-text="selectedResource.shop.phone"
            />
          </div>
        </div>
      </div>

      <UIHeading
        v-if="!selectedResourceId"
        title="Выберите интересующий вас заказ"
        tag="4"
        class="mb-6"
      />

      <div v-if="!selectedResourceId" class="mb-6 space-y-4">
        <UIPlane
          @click="selectedResourceId = resource.id"
          v-for="resource in resources"
          :title="`Заказ №${resource.id}`"
          :caption="`${resource.shop?.name} <br/> ${getDateFormat(
            resource.created_at,
            'DD MMM YYYY, в HH:mm',
          )}`"
        />
      </div>

      <div class="w-full flex justify-between">
        <UIButton
          class="w-full"
          size="xl"
          title="Закрыть"
          @click="context.close"
        />
      </div>
    </LoadingView>
  </div>
</template>

<script setup>
import { find } from 'lodash-es'
import { computed, inject, ref, watch } from 'vue'

import {
  IconChevronLeft,
  IconMapPin,
  IconPhone,
  IconX,
} from '@tabler/icons-vue'

import LoadingView from '@/components/loading/LoadingView.vue'
import UIButton from '@/components/ui/UIButton.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import UIPlane from '@/components/ui/UIPlane.vue'
import { getDateFormat } from '@/utils/global.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
  caption: {
    type: String,
    default: null,
  },
  id: {
    type: Number,
    default: null,
  },
})

console.log(props.id)

const selectedResourceId = ref(props.id)

const selectedResource = computed(() => {
  return find(resources.value, ['id', selectedResourceId.value])
})

const http = inject('http')

const isLoading = ref(true)
const isLoadingQr = ref(false)
const resources = ref([])
const qr = ref(null)

http('/profile/orders')
  .then(({ resources: r }) => {
    resources.value = r

    if (resources.value.length === 1) {
      selectedResourceId.value = resources.value[0].id
    }
  })
  .finally(() => (isLoading.value = false))

watch(
  () => selectedResourceId.value,
  () => {
    if (selectedResourceId.value) {
      qr.value = null

      isLoadingQr.value = true
      http(`/complaints/${selectedResourceId.value}/qr`)
        .then((data) => {
          qr.value = data
        })
        .finally(() => (isLoadingQr.value = false))
    }
  },
  {
    immediate: true,
  },
)
</script>
