<template>
  <div
    v-if="global.resources?.banner?.published"
    class="sticky z-[60] bg-white/80 text-black dark:bg-primary-1000/50 backdrop-blur py-4 border-b border-white/50 dark:border-primary-950/50 dark:text-white"
  >
    <div class="flex items-center justify-center gap-x-4 gap-y-2">
      <p class="text-sm leading-6 md:items-center items-start px-4 md:px-0">
        <strong class="font-bold" v-text="global.resources?.banner?.heading" />
        <svg
          viewBox="0 0 2 2"
          class="mx-2 inline h-0.5 w-0.5 fill-current"
          aria-hidden="true"
        >
          <circle cx="1" cy="1" r="1" />
        </svg>
        <span v-text="global.resources?.banner?.caption" />
      </p>
      <a
        @click="openWidget"
        href="#"
        class="md:mr-0 mr-4 flex rounded-full dark:bg-gray-600 bg-primary px-3.5 py-1 text-sm text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
        ><span class="block" v-text="global.resources?.banner?.button" />
      </a>
    </div>
  </div>
</template>

<script setup>
import { useGlobalStore } from '@/store/global.js'

const global = useGlobalStore()

function openWidget(e) {
  e.preventDefault()
  e.stopImmediatePropagation()
  document.querySelector('.help-widget').click()
}
</script>
