<template>
  <button
    type="button"
    class="select-none inline-flex disabled:cursor-not-allowed disabled:dark:bg-primary-950/50 disabled:text-black-500 disabled:bg-black-200 items-center justify-center rounded-lg border outline-none hover:transition-colors focus:outline-none focus:ring-1"
    :class="[
      uiClasses,
      position,
      { 'flex-row-reverse': iconPosition === 'right' },
      { 'pointer-events-none': isLoading },
    ]"
  >
    <span
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center pointer-events-none"
    >
      <component v-if="loadingIcon" :class="uiIcon.size" :is="loadingIcon" />
      <span v-else v-text="loadingPlaceholder" />
    </span>
    <span
      class="flex items-center pointer-events-none"
      :class="{ 'opacity-0': isLoading }"
    >
      <component
        v-if="icon"
        class="opacity-70"
        :class="[uiIcon.size, uiIcon.alignment, iconClasses]"
        :is="icon"
      />
      {{ title }}
    </span>
    <slot />
  </button>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  type: {
    type: String,
    default: '',
  },
  view: {
    type: String,
    default: '',
  },
  icon: {
    type: [Object, Function],
    default: () => {},
  },
  loadingIcon: {
    type: [Object, Function],
    default: () => {},
  },
  iconPosition: {
    type: String,
    default: 'left',
  },
  iconClasses: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
  position: {
    type: String,
    default: 'relative',
  },
  loadingPlaceholder: {
    type: String,
    default: 'Loading...',
  },
  isLoading: Boolean,
})

const size = {
  xs: 'px-2.5 py-1.5 text-xs leading-4',
  sm: 'px-3 py-2 text-sm font-medium leading-4',
  md: 'px-5 py-1 text-base',
  lg: 'px-6 py-2 text-base',
  xl: 'px-6 py-4 text-base leading-5',
}
const iconPositions = {
  left: {
    xs: '-ml-0.5 mr-1',
    sm: '-ml-0.5 mr-1',
    md: '-ml-1 mr-2',
    lg: '-ml-1 mr-2',
    xl: '-ml-1 mr-3',
  },
  right: {
    xs: '-mr-0.5 ml-1',
    sm: '-mr-0.5 ml-1',
    md: '-mr-1 ml-2',
    lg: '-mr-1 ml-2',
    xl: '-mr-1 ml-3',
  },
}
const iconSize = {
  xs: 'h-4 w-4',
  sm: 'h-4 w-4',
  md: 'h-4 w-4',
  lg: 'h-4 w-4',
  xl: 'h-6 w-6',
}

// Size and alignment(margins) for icon
const uiIcon = computed(() => {
  return {
    size: iconSize[props.size],
    alignment: props.title
      ? iconPositions[props.iconPosition][props.size]
      : 'm-auto',
  }
})
// Button style classes
const uiClasses = computed(() => {
  switch (props.type) {
    case 'secondary': {
      return `text-primary bg-primary-100 hover:bg-primary-200 border-transparent focus:ring-primary-100 ${
        size[props.size]
      }`
    }
    case 'white': {
      return `bg-white text-gray-950 dark:bg-transparent dark:text-white border-transparent focus:ring-primary ${
        size[props.size]
      }`
    }
    case 'gray':
      if (props.view === 'noline') {
        return `bg-transparent hover:text-gray-800 hover:dark:text-white border-transparent dark:text-gray-400 text-gray-600 ring-gray-600 focus:ring-gray-600 ${
          size[props.size]
        }`
      }
      if (props.view === 'outline') {
        return `bg-transparent hover:bg-gray-100 border-gray-600 text-gray-600 ring-gray-100 focus:ring-gray-100 ${
          size[props.size]
        }`
      }
      return `bg-mine-shaft-50 dark:bg-white/20 dark:text-white text-black hover:bg-mine-shaft-100 border-transparent focus:ring-mine-shaft-200 ${
        size[props.size]
      }`
    case 'danger':
      if (props.view === 'outline') {
        return `bg-white hover:bg-rose-50 border-rose-500 text-red-500 focus:ring-rose-50 ${
          size[props.size]
        }`
      }
      return `bg-rose-500 text-white hover:bg-rose-700 border-transparent focus:ring-rose-500 ${
        size[props.size]
      }`
    case 'accent':
      if (props.view === 'outline') {
        return `bg-transparent hover:bg-primary-50 border-primary-500 text-primary-500 focus:ring-primary-50 ${
          size[props.size]
        }`
      }
      return `bg-primary-500 text-white hover:bg-primary-700 border-transparent focus:ring-primary-500 ${
        size[props.size]
      }`
    default:
      return `text-white text-black bg-primary-600 hover:bg-primary-700 border-transparent focus:ring-slate-500 ${
        size[props.size]
      }`
  }
})
</script>
