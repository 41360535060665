<template>
  <button
    type="button"
    class="select-none inline-flex disabled:opacity-60 disabled:cursor-not-allowed items-center justify-center rounded-md border font-bold outline-none hover:transition-colors focus:outline-none focus:ring-1"
    :class="[
      uiClasses,
      position,
      { 'flex-row-reverse': iconPosition === 'right' },
      { 'pointer-events-none': isLoading },
    ]"
  >
    <span
      v-if="isLoading"
      class="absolute inset-0 flex items-center justify-center"
    >
      <component v-if="loadingIcon" :class="uiIcon.size" :is="loadingIcon" />
      <span v-else>Loading...</span>
    </span>
    <span class="flex items-center" :class="{ 'opacity-0': isLoading }">
      <component
        v-if="icon"
        :class="[uiIcon.size, uiIcon.alignment, iconClasses]"
        :is="icon"
      />
      {{ title }}
    </span>
    <slot />
  </button>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: false,
  },
  type: {
    type: String,
    default: '',
  },
  view: {
    type: String,
    default: '',
  },
  icon: {
    type: [Object, Function],
    default: () => {},
  },
  loadingIcon: {
    type: [Object, Function],
    default: () => {},
  },
  iconPosition: {
    type: String,
    default: 'left',
  },
  iconClasses: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
  position: {
    type: String,
    default: 'relative',
  },
  isLoading: Boolean,
})

const size = {
  xs: 'px-2.5 py-1.5 text-xs leading-4',
  sm: 'px-3 py-2 text-sm font-medium leading-4',
  md: 'px-4 py-2 text-sm',
  lg: 'px-4 py-2 text-base',
  xl: 'px-6 py-3 text-base',
}
const iconPositions = {
  left: {
    xs: '-ml-0.5 mr-1',
    sm: '-ml-0.5 mr-1',
    md: '-ml-1 mr-2',
    lg: '-ml-1 mr-2',
    xl: '-ml-1 mr-3',
  },
  right: {
    xs: '-mr-0.5 ml-1',
    sm: '-mr-0.5 ml-1',
    md: '-mr-1 ml-2',
    lg: '-mr-1 ml-2',
    xl: '-mr-1 ml-3',
  },
}
const iconSize = {
  xs: 'h-3 w-3',
  sm: 'h-4 w-4',
  md: 'h-5 w-5',
  lg: 'h-5 w-5',
  xl: 'h-5 w-5',
}

// Size and alignment(margins) for icon
const uiIcon = computed(() => {
  return {
    size: iconSize[props.size],
    alignment: props.title
      ? iconPositions[props.iconPosition][props.size]
      : 'm-auto',
  }
})
// Button style classes
const uiClasses = computed(() => {
  switch (props.type) {
    case 'white': {
      return `bg-white text-gray-950 dark:bg-transparent dark:text-white border-transparent focus:ring-primary ${
        size[props.size]
      }`
    }
    case 'gray':
      if (props.view === 'noline') {
        return `bg-transparent hover:text-white border-transparent text-gray-600 ring-gray-600 focus:ring-gray-600 ${
          size[props.size]
        }`
      }
      if (props.view === 'outline') {
        return `bg-transparent hover:bg-gray-100 border-gray-600 text-gray-600 ring-gray-100 focus:ring-gray-100 ${
          size[props.size]
        }`
      }
      return `bg-gray-sand-500 text-white hover:bg-gray-sand-700 border-transparent focus:ring-gray-sand-500 ${
        size[props.size]
      }`
    case 'danger':
      if (props.view === 'outline') {
        return `bg-white hover:bg-rose-50 border-rose-500 text-red-500 focus:ring-rose-50 ${
          size[props.size]
        }`
      }
      return `bg-rose-500 text-white hover:bg-rose-700 border-transparent focus:ring-rose-500 ${
        size[props.size]
      }`
    case 'accent':
      if (props.view === 'outline') {
        return `bg-white hover:bg-primary-50 border-primary-500 text-primary-500 focus:ring-primary-50 ${
          size[props.size]
        }`
      }
      return `bg-primary-500 text-white hover:bg-primary-700 border-transparent focus:ring-primary-500 ${
        size[props.size]
      }`
    default:
      return `dark:bg-slate-700 text-white dark:hover:bg-slate-600 bg-primary-500 hover:bg-primary border-transparent focus:ring-slate-500 ${
        size[props.size]
      }`
  }
})
</script>
