<template>
  <NotificationGroup
    v-for="item in notify"
    :key="item.group"
    :group="item.group"
  >
    <div
      class="fixed inset-0 flex items-start justify-end p-6 px-4 py-6 pointer-events-none z-[10000]"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <component
            v-for="notification in notifications"
            :key="notification.id"
            :is="item.component"
            :type="notification.type"
            :notification="notification"
          />
        </Notification>
      </div>
    </div>
  </NotificationGroup>
  <component :is="$route.meta.layout || 'div'" />
</template>

<script setup>
import { Notification, NotificationGroup } from 'notiwind'
import { useHead, useSeoMeta } from 'unhead'
import { inject, watch } from 'vue'

import Default from '@/components/notifications/Default.vue'

const theme = inject('theme')

useHead({
  titleTemplate: (title) => (title ? `Наш Cевер | ${title}` : 'Наш Cевер'),
})

useSeoMeta({
  description: 'Вместе сделаем город лучше',
  ogDescription: 'Вместе сделаем город лучше',
  ogTitle: 'Наш Север',
  ogImage: '/seo/og-default.jpg',
  twitterCard: 'summary_large_image',
})

const notify = [
  {
    group: 'default',
    component: Default,
  },
]

watch(
  () => theme.value,
  () => {
    if (theme.value === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
    useHead({
      meta: [
        {
          name: 'theme-color',
          content: theme.value === 'dark' ? '#13172f' : '#fff',
        },
      ],
    })
  },
  {
    immediate: true,
  },
)
</script>
