<template>
  <div class="flex relative flex-col m-auto w-full" :class="modalStyle">
    <img
      class="mb-4 sm:max-w-[400px] rounded-md border dark:border-gray-800"
      v-if="image"
      :src="image"
      :alt="title"
    />
    <h1 class="text-lg font-semibold mb-2" v-text="title" />
    <div
      v-if="caption"
      class="text-sm text-black-700 dark:text-black-300 mb-4 sm:max-w-[400px]"
      v-html="caption"
    />
    <div class="flex space-x-4 justify-end mt-2">
      <UIButton
        v-if="cancelText"
        v-text="cancelText"
        type="gray"
        view="noline"
        @click="emit('close')"
      />
      <UIButton v-text="confirmText" @click="emit('confirm')" />
    </div>
  </div>
</template>

<script setup>
import UIButton from '@/components/ui/UIButton.vue'
import { modalStyle } from '@/utils/ui.js'

defineProps({
  title: {
    type: String,
    default: 'Подтвердите действие',
  },
  image: {
    type: String,
    default: null,
  },
  caption: {
    type: String,
    default: '',
  },
  confirmText: {
    type: String,
    default: 'Подтвердить',
  },
  cancelText: {
    type: String,
    default: 'Отменить',
  },
})

const emit = defineEmits(['update:modelValue', 'confirm', 'close'])
</script>
