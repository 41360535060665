<template>
  <component :is="render" />
</template>

<script setup>
import { h } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
    required: true,
  },
  tag: {
    type: String,
    default: null,
    required: true,
    validator(value) {
      return ['1', '2', '3', '4', '5', '6'].includes(value)
    },
  },
  styles: {
    type: String,
    default: null,
    validator(value) {
      return ['1', '2', '3', '4', '5', '6'].includes(value)
    },
  },
})

function getStyle(level) {
  const levels = {
    1: 'text-xl font-bold md:text-4xl',
    2: 'text-lg font-bold md:text-3xl',
    3: 'text-md font-bold md:text-2xl',
    4: 'text-base font-bold md:text-xl',
    5: 'text-sm font-bold md:text-lg',
  }
  return levels[level] || 'font-semibold'
}

function render() {
  return h(
    `h${props.tag}`,
    { class: getStyle(props.styles || props.tag) },
    props.title,
  )
}
</script>
