import { defaultConfig, plugin as formkit } from '@formkit/vue'
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from '@tsparticles/slim'
import Particles from '@tsparticles/vue3'
import { createEventHook, useLocalStorage, useStorage } from '@vueuse/core'
import { useNProgress } from '@vueuse/integrations/useNProgress'
import Notifications from 'notiwind'
import { notify } from 'notiwind'
import { $fetch } from 'ofetch'
import { createPinia } from 'pinia'
import 'tippy.js/dist/tippy.css'
import { createHead } from 'unhead'
import { createApp } from 'vue'
import { createVfm } from 'vue-final-modal'
import { VueQueryPlugin } from 'vue-query'
import { plugin as VueTippy } from 'vue-tippy'
import { createYmaps } from 'vue-yandex-maps'

// if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { useAuthStore } from '@/store/auth.js'

import '@/plugins/echo.js'

import formkitConfig from '../../formkit.config.js'
import '../css/index.scss'
import App from './App.vue'
import router from './router.js'

const head = createHead()
const vfm = createVfm()
const r = router()
const app = createApp(App)
const pinia = createPinia()

app.use(Particles, {
  init: async (engine) => {
    // await loadFull(engine); // you can load the full tsParticles library from "tsparticles" if you need it
    await loadSlim(engine) // or you can load the slim version from "@tsparticles/slim" if don't need Shapes or Animations
  },
})

app.use(head)
app.use(
  createYmaps({
    apikey: '37d1f7c3-fa89-41bf-9ce2-6a939409db8d',
  }),
)

const theme = useStorage(
  'theme',
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
)

app.use(VueTippy, {
  defaultProps: {
    theme: 'sever',
  },
})
const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
}
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.use(Notifications)
app.use(formkit, defaultConfig(formkitConfig))
app.use(vfm)

const { isLoading, progress } = useNProgress()

const http = $fetch.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT ?? '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  onRequest: async ({ request, options }) => {
    if (!request.includes('suggestions')) {
      isLoading.value = true
    }

    const storage = useLocalStorage('logged-in-as-user', null)

    options.headers = {
      ...options.headers,
      'X-LOCALE': 'ru',
    }

    if (storage.value) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${storage.value}`,
      }

      return
    }

    const auth = useAuthStore()
    // Sync token
    if (auth.token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${auth.token}`,
      }
    }
  },
  onResponse: async ({ response }) => {
    setTimeout(() => (isLoading.value = false), 300)
  },
  onResponseError({ response }) {
    isLoading.value = false
    if (response.status === 419) {
      notify(
        {
          group: 'default',
          type: 'error',
          title: 'Ой!',
          text: 'Вы делаете слишком много запросов!',
        },
        4000,
      )
    }
  },
})

window.$http = http

app.provide('http', http)
app.provide('theme', theme)

app.provide('hooks', {
  complaintPing: createEventHook(),
})

pinia.use(({ store }) => {
  store.router = r
  store.http = http
})

app.use(r)
app.use(pinia)

const auth = useAuthStore()

auth.fetchUser()

app.mount('#app')
