<template>
  <div
    class="flex relative flex-col m-auto max-w-[620px] min-h-[620px] w-full"
    :class="modalStyle"
  >
    <button class="max-md:hidden" @click="context.close">
      <IconX
        class="absolute size-4 right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full"
      />
    </button>
    <div class="flex flex-col h-full">
      <div>
        <UIHeading
          title="Опишите вашу проблему"
          tag="2"
          styles="2"
          class="mb-6"
        />
        <p class="text-black-600 dark:text-black-400 max-w-[95%]">
          Также Вы можете оставить отзывы или предложения по работе сайта. Мы
          постараемся ответить в ближайшее время, ответ вы увидите в личном
          кабинете в разделе
          <RouterLink
            @click="context.close"
            to="/profile/messages"
            class="text-primary"
            >Моя активность — Сообщения</RouterLink
          >.
        </p>

        <div class="flex-col flex-1">
          <FormKit
            type="form"
            :actions="false"
            class="my-4"
            id="report"
            form-class="my-8"
            v-model="form"
          >
            <FormKit type="group" name="entities">
              <FormKit
                type="email"
                name="email"
                placeholder="example@mail.com"
                label="Email"
              />

              <FormKit
                type="qselect"
                name="type"
                placeholder="Другое"
                :options="reportTypes"
                label="Тема обращения"
              />

              <FormKit
                type="textarea"
                name="content"
                placeholder="Опишите вашу проблему"
                label="Описание проблемы"
                :classes="{ input: '!h-48 resize-none' }"
              />

              <FormKit
                type="file"
                name="files"
                label="Файл"
                :loading="disableIfLoading"
              />
            </FormKit>
          </FormKit>
        </div>

        <div class="space-x-4 flex w-full justify-end mt-auto">
          <UIButton
            @click="context.close"
            type="gray"
            view="noline"
            title="Отменить"
          />
          <div ref="createComplaintRef">
            <UIButton
              :is-loading="isLoading || filesLoading"
              :loading-icon="LoadingIcon"
              @click="createComplaint"
              title="Отправить"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { setErrors } from '@formkit/vue'
import { notify } from 'notiwind'
import { h, inject, ref, watch } from 'vue'
import { useTippy } from 'vue-tippy'

import { useAuthStore } from '@/store/auth.js'

import { IconX } from '@tabler/icons-vue'

import LoadingIcon from '@/components/loading/LoadingIcon.vue'
import UIAuthTippy from '@/components/ui/UIAuthTippy.vue'
import UIButton from '@/components/ui/UIButton.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import { modalStyle } from '@/utils/ui.js'

const emits = defineEmits(['force-close'])
const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const createComplaintRef = ref(null)

const isLoading = ref(false)

const http = inject('http')

const auth = useAuthStore()

const filesLoading = ref(false)

function disableIfLoading(value) {
  filesLoading.value = value
}

const { hide: hideComplaintTippy, show: showComplaintTippy } = useTippy(
  createComplaintRef,
  {
    content: h(UIAuthTippy, {
      body: 'Чтобы отправить обращение',
      hide: () => hideComplaintTippy(),
    }),
    trigger: 'manual',
    interactive: true,
  },
)

const reportTypes = ref([
  {
    label: 'Авторизация',
    value: 'auth',
  },
  {
    label: 'Народный тренер / народная команда',
    value: 'contests',
  },
  {
    label: 'Инициативы',
    value: 'initiatives',
  },
  {
    label: 'Работа сайта',
    value: 'site',
  },
  {
    label: 'Начисление баллов',
    value: 'wallets',
  },
  {
    label: 'Другое',
    value: 'other',
  },
])

const form = ref({
  category: 'reports',
  entities: {
    type: 'other',
    email: auth.user?.email,
  },
})

function createComplaint() {
  isLoading.value = true
  http('/complaints', {
    method: 'POST',
    body: form.value,
  })
    .then(() => {
      props.context.close()
      notify({
        group: 'default',
        type: 'success',
        title: 'Обращение отправлено',
        text: 'В ближайшее время на ваше обращение поступит ответ',
      })
    })
    .catch((e) => {
      isLoading.value = false
      if (e.data?.errors) {
        setErrors('report', e.data?.errors)
      }
    })
}
</script>
