import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    resources: window.Resources,
    home: window.HomeData,
  }),
  getters: {
    getSettlements() {
      return this.resources.settlements
    },
  },
})
