export default function createSocialAuthWindow(
  social,
  listener,
  w = 540,
  h = 740,
) {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop

  const defaultOptions = {
    top,
    left,
    width: w / systemZoom,
    height: h / systemZoom,
    menubar: 'no',
    toolbar: 'no',
    status: 'no',
    resizable: 'no',
    location: 'no',
    scrollbars: 'no',
    titlebar: 'no',
  }

  window.addEventListener('message', listener, false)

  const options = Object.keys(defaultOptions)
    .map((key) => `${key}=${defaultOptions[key]}`)
    .join(',')

  const newWindow = window.open(
    `/api/auth/socials/${social}`,
    '_blank',
    options,
  )

  let checkPopupInterval = setInterval(() => {
    if (newWindow.closed || document.hasFocus()) {
      clearInterval(checkPopupInterval)
      // Handle the case when the pop-up is closed or not in focus
      window?.removeEventListener('message', listener, false)
      newWindow.close()
    }
  }, 1000)

  if (window?.focus) newWindow.focus()
}
