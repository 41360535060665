<template>
  <div
    class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
  >
    <div
      class="flex items-center justify-center w-12 flex-none"
      :class="style[type].bg"
    >
      <component :is="icons[type]" class="text-white size-5" />
    </div>

    <div class="px-4 py-2 -mx-3">
      <div class="mx-3">
        <span
          class="font-semibold"
          :class="style[type].text"
          v-text="notification.title"
        />
        <p class="text-sm text-gray-600" v-text="notification.text" />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  IconAlertTriangle,
  IconCircleCheck,
  IconExclamationCircle,
  IconInfoCircle,
} from '@tabler/icons-vue'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  notification: {
    type: Object,
    default: {},
  },
})

const icons = {
  success: IconCircleCheck,
  error: IconAlertTriangle,
  warning: IconExclamationCircle,
  info: IconInfoCircle,
}

const style = {
  success: {
    text: 'text-green-500',
    bg: 'bg-green-500',
  },
  error: {
    text: 'text-rose-500',
    bg: 'bg-rose-500',
  },
  warning: {
    text: 'text-yellow-500',
    bg: 'bg-yellow-500',
  },
  info: {
    text: 'text-primary-500',
    bg: 'bg-primary-500',
  },
}
</script>
