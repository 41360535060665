<template>
  <div
    v-if="storage && auth.user"
    class="bg-gray-900 px-6 py-2.5 sm:px-3.5 sticky top-0 z-[9999]"
  >
    <div class="container flex items-center justify-between">
      <div class="text-sm leading-6 text-white">
        <strong
          class="font-semibold"
          v-text="
            `Вы авторизованы как другой пользователь (ID: ${auth.user?.id})`
          "
        />
      </div>
      <UIButton @click="clearSession" size="sm" title="Завершить сессию" />
    </div>
  </div>
</template>

<script setup>
import { useLocalStorage } from '@vueuse/core'

import { useAuthStore } from '@/store/auth.js'

import UIButton from '@/components/ui/UIButton.vue'

const auth = useAuthStore()
const storage = useLocalStorage('logged-in-as-user', null)

function clearSession() {
  storage.value = null
  auth.user = null

  setTimeout(() => {
    auth.fetchUser()
  }, 200)
}
</script>
