<script setup>
import { moveArrayElement, useSortable } from '@vueuse/integrations/useSortable'
import { ref } from 'vue'

import { IconGripVertical, IconTrash } from '@tabler/icons-vue'

import LoadingIcon from '@/components/loading/LoadingIcon.vue'

import UIButton from '../../../../frame/core/resources/js/components/UIButton.vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: [],
  },
  isSortable: {
    type: Boolean,
    default: true,
  },
  isDeletable: {
    type: Boolean,
    default: true,
  },
})

defineEmits(['remove', 'reload'])

const isImageLoading = ref(true)
const listContainerRef = ref(null)
const isSorting = ref(false)

useSortable(listContainerRef, props.modelValue, {
  sort: props.isSortable || false,
  ghostClass: 'ring-1',
  animation: 150,
  handle: '.drag-handle',
  onUpdate: (e) => {
    moveArrayElement(props.modelValue, e.oldIndex, e.newIndex)
  },
  onStart: () => {
    isSorting.value = true
  },
  onEnd: () => {
    isSorting.value = false
  },
})

function onLoadImage() {
  isImageLoading.value = false
}
</script>

<template>
  <div
    ref="listContainerRef"
    class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 mb-4"
  >
    <div
      v-for="(item, i) in modelValue"
      :key="item.name"
      class="relative rounded-lg"
    >
      <div
        v-if="isSortable"
        class="drag-handle bg-white shadow dark:bg-primary-900/70 p-1 rounded-md absolute left-2 top-2 z-20 cursor-move"
      >
        <IconGripVertical class="size-4" />
      </div>
      <div
        v-if="item.status ? item.status === 'success' : true"
        class="bg-white shadow dark:bg-primary-900/70 p-1 rounded-md absolute right-2 top-2 z-20 cursor-pointer"
        @click="$emit('remove', item.id, i)"
      >
        <IconTrash class="size-4" />
      </div>

      <div
        class="aspect-video bg-slate-50 relative dark:border-primary-950/50 dark:bg-primary-950/50 rounded-lg overflow-hidden shadow-xl shadow-slate"
      >
        <Transition name="fade" mode="out-in">
          <div
            v-if="item.status !== 'success' && item.status !== undefined"
            class="w-full h-full flex flex-col p-3 justify-center items-center z-0 relative"
          >
            <LoadingIcon v-if="item.status !== 'error'" class="size-6" />

            <div v-else class="flex items-center justify-center flex-col">
              <p v-text="'Что-то пошло не так'" class="mb-3" />
              <UIButton
                title="Попробовать еще раз"
                size="xs"
                @click="$emit('reload', item._id, i)"
              />
            </div>
          </div>
          <div v-else class="aspect-video">
            <img
              v-if="item.path"
              class="w-full h-full absolute inset-0 z-10 transition-opacity duration-500 object-contain backdrop-blur"
              :class="[isImageLoading ? 'opacity-0' : '']"
              :src="item.path"
              alt="image"
              @load="onLoadImage"
            />
            <div
              v-if="item.preview"
              class="w-full h-full absolute inset-0 z-0 opacity-70"
              :style="`background: url(${item.preview}) no-repeat center/cover;`"
            />
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
