<template>
  <div>
    <!--toggle-->
    <div
      class="help-widget fixed z-[1000] flex p-2.5 items-center ease-out justify-center hover:scale-110 active:scale-90 transition cursor-pointer shadow-md w-12 h-12 rounded-full bg-brand-4 bottom-6 right-4 sm:right-6"
      @click="isOpened = !isOpened"
    >
      <IconHelp class="text-white transition outline-none size-8" />
    </div>
    <!--widget-->
    <transition v-bind="fadeShiftScale">
      <div
        ref="widget"
        v-if="isOpened"
        class="bg-white dark:bg-neutral-900 overflow-hidden z-[9997] sm:rounded-xl p-6 sm:w-[100%] max-sm:left-0 max-sm:top-0 sm:max-w-[365px] sm:h-[600px] fixed bottom-0 sm:bottom-24 right-0 sm:right-14"
        :class="{ 'shadow-[0_0_50px_-0px_rgba(0,0,0,0.16)]': !content }"
      >
        <div class="absolute inset-0">
          <!--header-->
          <div
            class="h-16 sticky flex flex-col transition items-center py-4 bg-brand-4"
            :class="{
              'h-28': activeViewIndex === 2,
            }"
            style="transition: height 300ms"
          >
            <p
              v-if="navigationItems[activeViewIndex].showHeader !== false"
              class="text-white text-xl mt-0.5"
              v-text="navigationItems[activeViewIndex].title"
            />
            <div
              class="max-h-0 opacity-0 w-full px-4 overflow-hidden mt-2"
              :class="{ 'max-h-10 opacity-100': activeViewIndex === 2 }"
              style="
                transition:
                  max-height 300ms,
                  opacity 200ms;
              "
            >
              <input
                v-model="searchValue"
                class="rounded-md outline-none dark:text-black w-full px-4 py-1.5"
                placeholder="Поиск"
              />
            </div>
          </div>
          <!--content-->
          <div
            class="absolute left-0 -z-10 bg-gradient-to-b from-brand-4 to-transparent right-0 transition-transform h-[400px] scale-y-0 -translate-y-1/2"
            :class="{ 'scale-y-100 translate-y-0': activeViewIndex === 0 }"
          />
          <!--pages-->
          <div
            v-if="activeViewIndex === 0"
            class="absolute z-10 left-0 right-0 px-6 -mt-8"
          >
            <div class="flex justify-between items-center w-full">
              <p
                class="text-2xl md:text-3xl font-semibold text-white"
                v-text="'Нужна помощь?'"
              />
              <button @click="closeWidget">
                <IconX class="size-6 text-white" />
              </button>
            </div>
          </div>

          <div class="h-full overflow-y-auto" v-if="activeViewIndex === 0">
            <div class="space-y-4 p-6">
              <div
                @click="createComplaint"
                class="flex items-center justify-between bg-white shadow-lg rounded-md pt-4 pb-5 px-4 text-black cursor-pointer group"
              >
                <div class="flex flex-col flex-1">
                  <span
                    class="font-medium mb-0.5 group-hover:text-primary transition-colors"
                    v-text="'Проблема на сайте'"
                  />
                  <span
                    class="text-xs text-black-700 max-w-[80%]"
                    v-text="'Сообщить о проблеме на сайте'"
                  />
                </div>

                <IconMessage class="text-primary size-5" />
              </div>

              <!--              <div-->
              <!--                class="flex items-center justify-between bg-white shadow-lg rounded-md pt-4 pb-5 px-4 text-black cursor-pointer group ease-in-out"-->
              <!--                @click="openIframe"-->
              <!--              >-->
              <!--                <div class="flex flex-col flex-1">-->
              <!--                  <span-->
              <!--                    class="font-medium mb-0.5 group-hover:text-primary transition-colors"-->
              <!--                    v-text="'Сообщить о проблеме'"-->
              <!--                  />-->
              <!--                  <span-->
              <!--                    class="text-xs text-black-500 max-w-[80%]"-->
              <!--                    v-text="-->
              <!--                      'Сообщите о любой проблеме и получите ответ от исполнительных органов Мурманской области'-->
              <!--                    "-->
              <!--                  />-->
              <!--                </div>-->

              <!--                <IconDialpad class="text-primary w-5 h-5" />-->
              <!--              </div>-->

              <div
                class="flex flex-col text-sm bg-white shadow-lg rounded-md pt-4 pb-5 px-4 text-black group"
              >
                <span
                  >Email:
                  <a
                    class="text-primary-700"
                    href="mailto:support@nashsever51.ru"
                    >support@nashsever51.ru</a
                  >
                </span>

                <ul class="space-y-0.5 mt-1">
                  <li
                    v-for="item in links"
                    :key="item.link"
                    class="text-xs text-black-900 dark:text-black-700"
                  >
                    <RouterLink
                      :to="item.link"
                      v-text="item.title"
                      class="block py-1 hover:text-primary transition-colors"
                      @click="closeWidget"
                    />
                  </li>
                  <li
                    class="cursor-pointer py-1 text-xs text-black-900 dark:text-black-500 hover:text-primary dark:hover:text-primary transition-colors"
                    v-text="'Инструкции и документы по инициативам'"
                    @click="openInitiativesModal"
                  />
                </ul>
              </div>
            </div>
          </div>
          <div
            class="h-[calc(100%_-_136px)] overflow-y-auto"
            v-if="activeViewIndex === 1"
          >
            <div class="p-6">
              <span v-if="!complaints.length" class="text-center"
                >На текущий момент у вас нет обращений</span
              >
              <div v-else class="flex flex-col space-y-3">
                <RouterLink
                  v-for="item in complaints"
                  :key="item.key"
                  :to="`/profile/messages/${item.id}`"
                  class="relative"
                  @click="closeWidget"
                >
                  <UIPlane
                    title-classes="text-sm mr-4"
                    :title="`№${item.id} — ${item.title}`"
                    :caption="`От ${getDateFormat(item.created_at)}, ${
                      item.status
                    }`"
                  />

                  <span
                    v-if="item.notifications_count"
                    class="absolute -top-2 -right-2 flex h-4 w-4"
                  >
                    <span
                      class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"
                    />
                    <span
                      class="relative inline-flex rounded-full h-4 w-4 bg-sky-500"
                    />
                  </span>
                </RouterLink>
              </div>
            </div>
          </div>
          <!--FAQ-->
          <div
            class="h-[calc(100%_-_180px)] overflow-y-auto"
            v-if="activeViewIndex === 2"
          >
            <div class="space-y-2 p-4">
              <div
                v-for="item in faqSearchResults"
                :key="item.id"
                class="p-4 cursor-pointer rounded-md bg-neutral-100 dark:bg-black-900"
                @click="content = item"
              >
                <p v-text="item.title" />
              </div>
            </div>
          </div>
        </div>
        <!--bottom navigation-->
        <div
          class="px-6 pb-2 pt-3 bg-neutral-50 dark:bg-neutral-900 dark:border-neutral-700 border-t border-black-100 absolute bottom-0 left-0 right-0 flex items-center justify-around"
        >
          <div
            v-for="(item, idx) in navigationItems"
            :key="idx"
            class="flex flex-col text-black-800 dark:text-black-300 select-none items-center hover:text-black-950 dark:hover:text-black-500 cursor-pointer"
            :class="{
              '!text-brand-4 dark:!text-brand-1 hover:text-brand-4 hover:opacity-80':
                activeViewIndex === idx,
              'pointer-events-none opacity-30': item.disabled,
            }"
            @click="activeViewIndex = idx"
          >
            <component class="size-6" :is="item.icon" />
            <p class="mt-1 text-sm" v-text="item.tab ?? item.title" />
          </div>
        </div>
      </div>
    </transition>
    <!--view article-->
    <transition v-bind="content ? fadeSlideLeft : fadeSlideRight">
      <div
        v-if="isOpened && content"
        class="bg-white dark:bg-neutral-800 sm:max-w-[480px] h-full sm:h-[600px] overflow-hidden fixed left-0 sm:left-auto top-0 sm:top-auto bottom-0 sm:bottom-24 right-0 sm:right-14 z-[9999] shadow-[0_0_50px_-0px_rgba(0,0,0,0.16)] sm:rounded-lg py-0"
      >
        <div class="overflow-auto overscroll-contain h-full sm:h-[600px]">
          <div
            class="sticky top-0 bg-white dark:bg-neutral-800 border-b border-black-200 dark:border-black-500 flex py-4 justify-between items-center px-6"
          >
            <UIHeading
              class="flex-1"
              tag="4"
              styles="4"
              :title="content.title"
            />
            <IconX
              class="hover:bg-black-100 bg-white dark:bg-transparent dark:hover:bg-black-700 border border-neutral-200 dark:border-neutral-600 cursor-pointer rounded-md size-9 p-1"
              @click="content = null"
            />
          </div>
          <div
            class="!p-0 !px-6 my-4"
            :class="proseContent"
            v-html="content.content"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse'
import { findIndex } from 'lodash-es'
import { computed, inject, ref, watch } from 'vue'

import { useAuthStore } from '@/store/auth.js'
import { useGlobalStore } from '@/store/global.js'

import {
  IconDialpad,
  IconHelp,
  IconHome,
  IconMessage,
  IconMessageCircle,
  IconX,
} from '@tabler/icons-vue'

import ContestDocsModal from '@/components/modals/ContestDocsModal.vue'
import ReportModal from '@/components/modals/ReportModal.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import UIPlane from '@/components/ui/UIPlane.vue'
import useModalDynamic from '@/plugins/useModalDynamic.js'
import { getDateFormat, useIframeModal } from '@/utils/global.js'
import {
  fadeShiftScale,
  fadeSlideLeft,
  fadeSlideRight,
} from '@/utils/transitions.js'
import { proseContent } from '@/utils/ui.js'

const isOpened = ref(false)
const activeViewIndex = ref(0)

const global = useGlobalStore()

const widget = ref(null)

const hooks = inject('hooks')

onClickOutside(widget, (event) => closeWidget)

function closeWidget() {
  isOpened.value = false
}

const authStore = useAuthStore()

const navigationItems = computed(() => [
  { title: 'Главная', icon: IconHome, showHeader: false },
  { title: 'Обращения', icon: IconMessageCircle, disabled: !authStore.user },
  { title: 'Вопросы и ответы', tab: 'Вопросы', icon: IconHelp },
])

const links = ref([
  {
    title: 'Политика конфиденциальности',
    link: '/policy',
  },
  {
    title: 'Пользовательское соглашение',
    link: '/agreement',
  },
  {
    title: 'Общая информация о проекте',
    link: '/about/general',
  },
])

const content = ref(null)

const searchValue = ref('')

const http = inject('http')

const faq = ref([])

const { results } = useFuse(searchValue, faq, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: {
    keys: ['id', 'title', 'content'],
    threshold: 0.1,
  },
})

const faqSearchResults = computed(() => {
  return results.value.map((i) => i.item)
})

http('/help').then(({ questions, complaints }) => {
  faq.value = questions
})

const complaints = ref([])

watch(
  () => authStore.user,
  () => {
    if (authStore.user) {
      http('/complaints').then(({ resources }) => {
        complaints.value = resources
      })
    } else {
      complaints.value = []
    }
  },
  {
    immediate: true,
  },
)

hooks.complaintPing.on(({ complaint }) => {
  const index = findIndex(complaints.value, ['id', complaint.id])

  if (index !== -1) {
    complaints.value[index].notifications_count++
  }
})

function createComplaint() {
  isOpened.value = false
  useModalDynamic({
    opened: true,
    component: ReportModal,
  })
}

function openIframe() {
  isOpened.value = false
  useIframeModal('https://pos.gosuslugi.ru/form/?opaId=217422')
}

function openInitiativesModal() {
  closeWidget()
  useModalDynamic({
    opened: true,
    component: ContestDocsModal,
    compAttrs: {
      options: global.resources.initiatives,
    },
  })
}
</script>
