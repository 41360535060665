<template>
  <input
    v-model="context.value"
    v-maska
    :data-maska="context.attrs.mask ?? '+7 (###) ###-##-##'"
    :placeholder="context.attrs.placeholder ?? '+7 (999) 999-66-33'"
    type="tel"
    :class="context.classes.input"
    :disabled="context.disabled"
    @update:model-value="handleInput($event)"
  />
</template>

<script setup>
import { vMaska } from 'maska'

const props = defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

function handleInput(e) {
  props.context.node.input(e)
}
</script>
