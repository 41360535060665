<template>
  <div
    class="relative dark:text-white m-auto max-w-[420px] w-full"
    :class="context.wrapperClass"
  >
    <button
      class="max-md:hidden absolute right-4 md:-right-2 -top-2 translate-x-full text-white -translate-y-full"
      @click="context.close"
    >
      <IconX class="size-4" />
    </button>
    <div>
      <UIHeading class="mb-3" title="Проверьте ваши данные" tag="2" />
      <div class="space-y-2 text-black-600 dark:text-black-200">
        <p
          v-text="
            'Возможно, что в Вашем личном кабинете на портале Госуслуг не указаны данные, которые необходимы для полноценной работе в системе:'
          "
        />

        <div class="prose dark:prose-invert">
          <ul class="">
            <li v-for="(item, i) in options" :key="i">
              {{ item }}
            </li>
          </ul>
        </div>
        <p
          v-text="
            'Если все данные указаны верно, а ошибка продолжает появляться — опишите вашу проблему в обращении.'
          "
        />
      </div>
      <UIButton
        class="mt-8"
        size="lg"
        title="Сообщить о проблеме"
        @click="callReportModal"
      />
    </div>
  </div>
</template>

<script setup>
import { IconX } from '@tabler/icons-vue'

import ReportModal from '@/components/modals/ReportModal.vue'
import UIButton from '@/components/ui/UIButton.vue'
import UIHeading from '@/components/ui/UIHeading.vue'
import useModalDynamic from '@/plugins/useModalDynamic.js'

const props = defineProps({
  context: {
    type: Object,
    default: {},
  },
})

const options = [
  'Фамилия',
  'Имя',
  'Отчество',
  'Дата рождения',
  'Email адрес',
  'Номер телефона',
  'Место постоянной регистрации',
]

function callReportModal() {
  props.context.close()

  useModalDynamic({
    opened: true,
    component: ReportModal,
  })
}
</script>
