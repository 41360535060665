<template>
  <div
    class="w-full rounded-md flex px-4 py-2 gap-2 items-center bg-white dark:bg-primary-950 dark:text-white ring-1 ring-black/20 dark:ring-white/20 min-w-0"
  >
    <a
      v-if="readonly"
      class="absolute inset-0 z-10"
      :href="context.path"
      download
      target="_blank"
    />
    <component
      class="size-6 mr-2 flex-none text-primary-600 dark:text-primary-200"
      :is="icons[context.mimeType] || IconFile"
    />
    <div class="flex-1 flex flex-col justify-between min-w-0">
      <span
        class="truncate text-sm font-medium"
        :title="context.name"
        v-text="context.name"
      />
      <div class="flex gap-2 items-center">
        <p
          class="text-xs whitespace-nowrap opacity-50"
          v-text="getHumanSizeOptimized(context.size)"
        />
        <div
          class="text-xs text-rose-500 truncate"
          :title="context.message || 'Ошибка'"
          v-if="context.status === 'error'"
          v-text="context.message || 'Ошибка'"
        ></div>
        <div class="text-xs text-slate-500" v-if="context.status === 'waiting'">
          Ожидание загрузки
        </div>
        <div
          v-if="context?.status === 'pending'"
          class="w-full flex items-center"
        >
          <div class="h-1 w-full bg-slate-400 rounded relative overflow-hidden">
            <div
              :style="`width: ${context.progress}%`"
              class="transition-all bg-blue-600 h-full w-0"
            >
              <div class="flare" />
            </div>
          </div>
          <div
            class="text-xs w-9 text-right flex-none"
            v-text="`${context.progress}%`"
          />
        </div>
      </div>
    </div>
    <div v-if="!readonly" class="flex gap-2">
      <button
        v-if="context.status === 'error'"
        title="Повторить загрузку"
        :class="buttonClass"
        @click.prevent="$emit('reload')"
      >
        <IconReload class="size-4 m-auto" />
      </button>
      <button
        v-if="
          context.status === 'success' ||
          context.status === 'error' ||
          !context.status
        "
        title="Удалить файл"
        :class="buttonClass"
        @click.prevent="$emit('remove')"
      >
        <IconX class="size-4 m-auto" />
      </button>
      <button
        v-if="context.status === 'pending'"
        title="Остановить загрузку"
        :class="buttonClass"
        @click.prevent="$emit('stop')"
      >
        <IconPlayerStop class="size-4 m-auto" />
      </button>
    </div>
  </div>
</template>

<script setup>
import {
  IconFile,
  IconFileTypeDoc,
  IconFileTypePdf,
  IconFileTypePpt,
  IconFileTypeXls,
  IconFileTypeZip,
  IconMovie,
  IconPhoto,
  IconPlayerStop,
  IconReload,
  IconVinyl,
  IconX,
} from '@tabler/icons-vue'

const icons = {
  pdf: IconFileTypePdf,
  excel: IconFileTypeXls,
  word: IconFileTypeDoc,
  ppt: IconFileTypePpt,
  image: IconPhoto,
  video: IconMovie,
  archive: IconFileTypeZip,
  audio: IconVinyl,
}

const buttonClass = 'size-6 flex rounded-full dark:bg-primary-1000 bg-black/10'

defineProps({
  context: {
    type: Object,
    default: {},
    require: true,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['remove', 'reload', 'stop'])

function getHumanSizeOptimized(fileSizeInBytes) {
  const byteUnits = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const base = 1024
  let i = Math.floor(Math.log(fileSizeInBytes) / Math.log(base))
  fileSizeInBytes /= Math.pow(base, i)
  return `${fileSizeInBytes.toFixed(2)} ${byteUnits[i]}`
}
</script>
<style scoped>
.flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 15px;
  transform: skewX(-15deg);
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 1.2s infinite linear;
}

@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
</style>
