<script setup>
const props = defineProps({
  icon: {
    type: [Object, Function],
    default: null,
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Выбрано',
  },
  modelValue: {
    type: Array,
    default: [],
  },
  max: {
    type: Number,
    default: 2,
  },
  labelBy: {
    type: String,
    default: 'label',
  },
})
</script>

<template>
  <button
    class="inline-flex gap-2 items-stretch justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground rounded-md px-3 text-xs h-8 border-dashed"
  >
    <span class="flex items-center gap-2">
      <component v-if="icon" :is="icon" class="size-4 flex-none" />
      <span v-if="label" v-text="label" />
    </span>
    <span
      v-if="modelValue && modelValue.length"
      class="border-l border-muted pl-2 space-x-1 flex items-center overflow-hidden"
    >
      <span
        v-if="modelValue && modelValue.length <= props.max"
        v-for="(item, i) in modelValue"
        :key="i"
        class="block w-full max-w-[60px] md:max-w-[120px] truncate overflow-hidden items-center border py-0.5 text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 rounded-sm px-1 font-normal"
      >
        {{ item[labelBy] || item }}
      </span>
      <span
        v-else
        v-text="
          `${placeholder} ${modelValue && modelValue.length ? modelValue.length : ''}`
        "
      />
    </span>
  </button>
</template>

<style scoped></style>
